import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { eSesjaSocketParserService } from "./esesja.socket.parser.service"
import { eSesjaEventEmitter } from "../helpers/esesja.event.emitter";
import eSesjaSocketAction from "./esesja.socket.action";
import io from 'socket.io-client';
import { encryptStorage } from "../services/encrypt.service"

class eSesjaInteractiveService {
    constructor() {
        this._hubConfig = {
            address: "/esesja-live",
            logLevel: LogLevel.Error
        }

        this._socketConfig = {
            address: "https://com.esesja.pl"
        }

        this._connectionState = {
            hub: false,
            socket: false,
        }
        this._debug = true;
        this.events = new eSesjaEventEmitter();
        this._councilId = -1;

        this._socketParser = new eSesjaSocketParserService();
        this._hubConnection = {};
        this._socketConnection = {};
        this._sessionId = null;
        this._parametrizeHubConnection();
    }
    //settery i gettery 
    get hubConfig() {
        return this._hubConfig;
    }
    get socketConfig() {
        return this._socketConfig;
    }
    get connectionComputedState() {
        return this._connectionState.hub && this._connectionState.socket;
    }
    get connectionState() {
        return this._connectionState;
    }

    //create connection
    //hub
    _parametrizeHubConnection() {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(this._hubConfig.address, { accessTokenFactory: () => encryptStorage.getItem("token") })
            .withAutomaticReconnect()
            .configureLogging(this._hubConfig.logLevel)
            .build();
    }

    async _hubConnect() {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!")

        this._hubConnection.on("UpdateDiscussionListAdmin", data => { this.events.emit("SignalRUpdateDiscussionListAdmin", data); });
        this._hubConnection.on("RefreshGuestLists", data => { this.events.emit("SignalRRefreshGuestLists", data); });
        //this._hubConnection.on("VoteResultChanged", data => { this.events.emit("SignalRVoteResultChanged", data); });
        this._hubConnection.on("EndsPoints", data => { this.events.emit("SignalREndsPoints", data); });
        this._hubConnection.onreconnected( async () => { 
            // console.log("Connection restored", connectionId);
            // this.events.emit("SignalRReconnected", connectionId); 
            await this.joinRoom(this._sessionId, true);
        });

        this._hubConnection.on("QuorumSummaryReults", async (councilid, obj) => {
            this.events.emit("SignalRQuorumSummaryReults", obj);
        });

        this._hubConnection.on("QuorumResultsChanged", async (sessionId, obj) => {
            this.events.emit("SignalRQuorumResultsChanged", obj)
        });
            
        this._hubConnection.on("InteractiveSessionStateChange", status => {
            this.events.emit("SignalRInteractiveSessionStateChange", status)
        });

        //this._hubConnection.on("CurrentAgendaItems", async (obj) => {
        //    this.events.emit("CurrentAgendaItems", obj);
        //});

        this._hubConnection.on("ReloadSessionForCaller", async (obj) => {
            this.events.emit("ReloadSessionForCaller", obj);
        });

        if (this._connectionState.hub == false) {
            await this._hubConnection.start();
            this._connectionState.hub = true;
        }
        
    }

    async joinRoom(sessionId, fromReconnect = false) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!")

        this._sessionId = sessionId;
        return await this._hubConnection.invoke("Join",  this._sessionId, fromReconnect);
    }

    async leaveRoom(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!")

        await this._hubConnection.invoke("Leave", sessionId);
        this._sessionId = null; 
    }

    async startCheckQuorum(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("StartCheckQuorum", parseInt(sessionId));
    }
    async cancelCheckQuorum(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("CancelCheckQuorum", parseInt(sessionId));
    }

    async quorumResultsChanged(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("QuorumResultsChanged", parseInt(sessionId));
    }

    async addUserToRequestSpeech(userId, sessionId, type) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("AddUserToRequestSpeech", parseInt(userId), parseInt(sessionId), parseInt(type));
    }

    async removeUserFromRequestSpeech(userId, sessionId, discussionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");
        return await this._hubConnection.invoke("RemoveUserFromRequestSpeech", parseInt(userId), parseInt(discussionId), parseInt(sessionId)); 
    }

    async addTimeToSpeakerForPublic(userId, sessionId, discussionId, time) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");
        return await this._hubConnection.invoke("AddTimeToSpeakerForPublic", parseInt(userId), parseInt(discussionId), parseInt(sessionId), parseInt(time));
    }

    async discussionChangeCallOtherHubs(userId, sessionId, action) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");
        return await this._hubConnection.invoke("DiscussionChangeCallOtherHubs", parseInt(userId), parseInt(sessionId), action);
    }

    async sendMainViewToCameraIntegration(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");
        return await this._hubConnection.invoke("SendMainViewToCameraIntegration", parseInt(sessionId));
    }

    async giveUserSpeech(requestUserId, discussionId, sessionId, source) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("GiveUserSpeech", parseInt(requestUserId), parseInt(discussionId), parseInt(sessionId), parseInt(source));
    }

    async openResultVotingModal(sessionId, result, summary) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("OpenResultVotingModal", parseInt(sessionId), result, summary);
    }

    async endUserSpeech(requestUserId, discussionId, sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("EndUserSpeech", parseInt(requestUserId), parseInt(discussionId), parseInt(sessionId));
    }

    async addUserToFavourite(requestUserId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("AddUserToFavourite", parseInt(requestUserId));
    }

    async addNewGuest(username, role) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("AddNewGuest", username, role);
    }

    async removeGuest(requestUserId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("RemoveGuest", parseInt(requestUserId));
    }

    //async startVoting(votingId, sessionId, pointId) {
    //    if (this._hubConnection === null)
    //        throw new Error("Hub is not parametrized!");

    //    return await this._hubConnection.invoke("StartVoting", parseInt(votingId), parseInt(sessionId), parseInt(pointId));
    //}

    //async cancelVoting(votingId, sessionId, pointId) {
    //    if (this._hubConnection === null)
    //        throw new Error("Hub is not parametrized!");

    //    return await this._hubConnection.invoke("CancelVoting", parseInt(votingId), parseInt(sessionId), parseInt(pointId));
    //}

    //async partialVotingResult(votingId, sessionId, pointId) {
    //    if (this._hubConnection === null)
    //        throw new Error("Hub is not parametrized!");

    //    return await this._hubConnection.invoke("PartialVotingResult", parseInt(votingId), parseInt(sessionId), parseInt(pointId));
    //}

    //async saveVoting(votingId, sessionId, pointId, skipResults) {
    //    if (this._hubConnection === null)
    //        throw new Error("Hub is not parametrized!");

    //    return await this._hubConnection.invoke("SaveVoting", parseInt(votingId), parseInt(sessionId), parseInt(pointId), skipResults);
    //}

    async closeResultVotingModal(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("CloseResultVotingModal", parseInt(sessionId));
    }

    async closeResultCheckQuorumModal(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("closeResultCheckQuorumModal", parseInt(sessionId));
    }

    async startBreak(breakTime, sessionId, timestamp) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("StartBreak", parseInt(breakTime), parseInt(sessionId), timestamp);
    }

    async stopBreak(breakTime, sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("StopBreak", parseInt(breakTime), parseInt(sessionId));
    }

    async showVotingResultsLive(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("ShowVotingResultsLive", parseInt(sessionId));
    }

    async hideVotingResultsLive(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("HideVotingResultsLive", parseInt(sessionId));
    }

    async changeActivePointInSession(pointId, sessionId, status) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("ChangeActivPoint", parseInt(pointId), parseInt(sessionId), status);
    }

    async changeInAgenda(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("ChangeInAgenda", parseInt(sessionId));
    }

    async changeInAgendaWithSendToTablet(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("ChangeInAgendaWithSendToTablet", parseInt(sessionId));
    }

    async reloadSession(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("ReloadSessionForCallerView", parseInt(sessionId));
    }

    async openAttachment(sessionId, pointId, attachmentId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("OpenAttachment", parseInt(sessionId), parseInt(pointId), parseInt(attachmentId));
    }

    async changeAttachmentPage(sessionId, page, scale) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("ChangeAttachmentPage", parseInt(sessionId), parseInt(page), parseInt(scale));
    }

    async closeAttachment(sessionId) {
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("CloseAttachment", parseInt(sessionId));
    }

    async changeInteractiveSessionStatus(sessionId, status){
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("ChangeInteractiveSessionStatus", parseInt(sessionId), status);
    }

    async activePlaceholder(sessionId, placeholderId){
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

        return await this._hubConnection.invoke("ChangePlaceholderStatus", parseInt(sessionId), parseInt(placeholderId), true);
    }

    async deActivePlaceholder(sessionId){
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!");

            return await this._hubConnection.invoke("ChangePlaceholderStatus", parseInt(sessionId), -1, false);
    }

    //socket methods
    async _parametrizeSocektConnection() {
        return await new Promise(resolve => {
            this._socketConnection = io(this._socketConfig.address);
            this._socketConnection.on("connect", async () => {
                await this._socketConnection.emit('viewerconsole', this._councilId); 
                this._connectionState.socket = true;
                resolve(true);
            });
        })
    }
    async _sockectConnected() {
        if (this._councilId === -1)
            throw new Error("Please specify council id");

        await this._socketConnection.emit('viewerconsole', this._councilId); 
        
    }
    async _socketCommandReceived(command) {
        let result = this._socketParser.parse(command);
        if (result.action.name === eSesjaSocketAction.StartVoting.name) {
            await this._socketConnection.emit("votingconsole", result.data.votingId);
        }

        this.events.emit(result.action.name, result.data);

    }
    async start(councilId) {
        this._councilId = parseInt(councilId);
        await this._parametrizeSocektConnection();
        await this._hubConnect();

        if (this.connectionComputedState) {
            this._socketConnection.on("command", (command) => this._socketCommandReceived(command));
        }

        return this.connectionComputedState;

    }
    async stop() {
        this._councilId = -1;
        if (this._hubConnection === null)
            throw new Error("Hub is not parametrized!")

        await this._hubConnection.stop();

        this._hubConnection.off("UpdateDiscussionListAdmin");
        this._hubConnection.off("RefreshGuestLists");
        //this._hubConnection.off("VoteResultChanged");
        this._hubConnection.off("EndsPoints");
        this._hubConnection.off("QuorumResultsChanged");

        this._socketConnection.disconnect();
        this._sessionId = null;
        this._connectionState.hub = false;
        this._connectionState.socket = false;
    }
}

export default new eSesjaInteractiveService();